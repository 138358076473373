<template>
  <div class="home">
    <div class="title">面包人小队</div>
    <div class="navbar">
      <router-link class="link" to="/">主页</router-link>
      <router-link class="link" to="/forum">工程</router-link>
      <router-link class="link" to="/team">成员</router-link>
      <router-link class="link" to="/kook">开黑</router-link>
      <router-link class="link" to="/file">文件分享</router-link>
      <router-link class="link" to="/white">加入我们</router-link>
    </div>
    <router-view></router-view>
    <div class="beian">
      <!-- <a href="https://beian.miit.gov.cn">公安备案xxxxxxxxxx</a> -->
      <!-- &nbsp;&nbsp;&nbsp;&nbsp; -->
      <!-- <a href="https://beian.miit.gov.cn">黑ICP备2024024655号-1</a> -->
    </div>
  </div>
</template>

<script>
import { L2Dwidget } from 'live2d-widget/lib/L2Dwidget.common.js'
export default {
  name: 'LayoutView',
  data() {
    return {
      msg: ['面包人小队欢迎你', '我眼馋那个面包好久了~', '如你所见,我是面包小队的吉祥物面包喵~', '今天吃点什么好呢?', '我的梦中情猫是三花猫♥', '百猫全书中能看到我的身影~', '喵喵喵?']
    }
  },
  mounted() {
    setTimeout(() => {
      L2Dwidget.init({
        tagMode: false,
        log: false,
        model: {
          jsonPath: "https://unpkg.com/live2d-widget-model-hijiki@1.0.5/assets/hijiki.model.json",
        },
        dialog: {
          enable: true, //是否开启对话框
        },
        display: { position: 'right', width: 250, height: 400 },
        mobile: { show: false }
      })
    }, 1)
    setTimeout(() => {
      let dialogTitle = document.querySelector('.live2d-widget-dialog')
      if (dialogTitle) {
        dialogTitle.style.opacity = 1
        dialogTitle.innerText = this.msg[0]

        let index = 0
        setInterval(() => {
          index = (index + 1) % this.msg.length;
          dialogTitle.innerText = this.msg[index]
        }, 10000);
      }
    }, 500)
  }
}
</script>

<style scoped>
.navbar {
  color: white;
  font-size: 1.5625rem;
  font-weight: 700;
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
@media (max-width: 1300px) {
  .navbar {
    width: 80%;
    font-size: 1.5rem !important; /* 在小屏幕下增加文字大小 */
  }
}
@media (max-width: 1000px) {
  .navbar {
    width: 100%;
    font-size: 1.5rem !important; /* 在小屏幕下增加文字大小 */
  }
}
@media (max-width: 400px) {
  .navbar {
    font-size: 1.1rem !important; /* 在小屏幕下增加文字大小 */
  }
}
@media (max-width: 350px) {
  .navbar {
    font-size: 0.9rem !important; /* 在小屏幕下增加文字大小 */
  }
}

.navbar a {
  color: rgba(255, 255, 255, 0.5); /* 设置半透明的白色 */
  text-decoration: none !important;
  transition: opacity 0.3s; /* 添加渐变效果 */
}

.navbar a:hover {
  color: #fff;
  text-decoration: underline;
}

.title {
  text-align: center;
  font-family: "华文彩云", "Microsoft JhengHei";
  color: rgba(255, 255, 255, 0.5);
  font-size: 3.125rem;
  margin: 2.5rem 0;
  text-shadow: 0 0 10px rgba(255, 255, 255, 1);
}

.link:focus {
  color: #fff;
}

/* .beian {
  position: absolute;
  bottom: 3%;
  left: 42%;
}

.beian a {
  text-decoration: none;
  color: #fff;
} */
</style>